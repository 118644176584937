
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    // 测试服
    // merchant_id: 1726,
    // appid: 'wx6e0c02068d6e2632',
    // redirect_uri: 'https://www.co-op.ai/qls_gw/login',

    merchant_id: 2410,
    appid: 'wx0e0a835894f596d3',
    redirect_uri: 'https://t.co-op.ai/qls_gw/login',
    
    //id是旅游资讯
    id:'',
    //游记攻略id
    introductionId:'',
    //获取文章详情id
    reviewId:'',
    cur_member: ''
  },
  getters: {

  },
  mutations: {
    updateMember(state,obj){
      state.cur_member = obj;
    },
    goDetailsId(state,id=''){
     return(state.id=id)
    },
    getId(state,id=''){
        return(state.introductionId=id)
    },
    getReviewsId(state,id=''){
       return(state.reviewId=id)
    }
  },
  actions: {
  },
  modules: {
  }
})
