import { render, staticRenderFns } from "./tabBar.vue?vue&type=template&id=20907121&scoped=true&"
import script from "./tabBar.vue?vue&type=script&lang=js&"
export * from "./tabBar.vue?vue&type=script&lang=js&"
import style0 from "./tabBar.vue?vue&type=style&index=0&id=20907121&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20907121",
  null
  
)

export default component.exports