import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";
import { MessageBox } from "element-ui";
import { Message } from "element-ui";
import { memberLogin } from '@/utils/activity'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/qls_gw/home',
  },
  {
    path: '/qls_gw',
    redirect: '/qls_gw/home',
  },
  //PC首页
  {
    path: '/qls_gw/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '首页',
      position: '1'
    }
  },
  {
    path: '/qls_gw/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/qls_gw/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/qls_gw/forgot',
    name: 'forgot',
    component: () => import('../views/forgotPassword.vue'),
    meta: {
      title: '忘记密码',
    }
  },
  {
    path: '/qls_gw/data_analysis',
    name: 'data_analysis',
    beforeEnter: (to, from, next) => {
      let user = sessionStorage.getItem('user_name')
      if (!user) {
        if (to.path !== '/qls_gw/login') {
          next({ path: "/qls_gw/login", query: { nolook: 'details' } })
        } else {
          next()
        }
      } else {
        next()
      }
    },
    component: () => import('../views/dataAnalysis/index.vue'),
    meta: {
      title: '数据分析',
    }
  },
  {
    path: '/qls_gw/cx_phone',
    name: 'cx_phone',
    component: () => import('../views/TravelBrochure/traPhone'),
    meta: {
      title: '常用电话',
    }
  },
  {
    path: '/qls_gw/jq_jj',
    name: 'jq_jj',
    component: () => import('../views/scenicSpot/introDuction'),
    meta: {
      title: '景区简介',
    }
  },
  {
    path: '/qls_gw/pl_dt',
    name: 'pl_dt',
    component: () => import('../views/PlayAround/index'),
    meta: {
      title: '玩转青龙山',
    }
  },
  {
    path: '/qls_gw/active',
    name: 'active',
    redirect: '/qls_gw/events',
    component: () => import('../views/acTivity/index'),
    meta: {
      title: '景区活动',
      position: '5'
    },
    children:[
      {
        path: '/qls_gw/events',
        name: 'events',
        component: () => import('../views/acTivity/event'),
        meta: {
          title: '景区活动',
          position: '5'
        }
      },
      {
        path: '/qls_gw/active_details',
        name: 'event_details',
        component: () => import('../views/acTivity/details'),
        meta: {
          title: '景区活动',
          position: '5'
        }
      },
      {
        path: '/qls_gw/articles',
        name: 'articles',
        component: () => import('../views/acTivity/article'),
        meta: {
          title: '新闻动态',
          position: '5'
        }
      },
      {
        path: '/qls_gw/article_details',
        name: 'article_details',
        component: () => import('../views/acTivity/article_detail'),
        meta: {
          title: '新闻动态',
          position: '5'
        }
      },
      {
        path: '/qls_gw/notices',
        name: 'notices',
        component: () => import('../views/acTivity/notices'),
        meta: {
          title: '景区公告',
          position: '5'
        }
      },
      {
        path: '/qls_gw/notice_details',
        name: 'notice_detail',
        component: () => import('../views/acTivity/notice_detail'),
        meta: {
          title: '景区公告',
          position: '5'
        }
      },
    ]
  },
  {
    path: '/qls_gw/purChase',
    name: 'purChase',
    component: () => import('../views/purChase/index'),
    meta: {
      title: '订购服务',
      position: '8'
    }
  },
  {
    path: '/qls_gw/purChase_details',
    name: 'purChase_details',
    component: () => import('../views/purChase/details'),
    meta: {
      title: '订购详情',
      position: '8'
    }
  },
  {
    path: '/qls_gw/introduction_details',
    name: 'introduction_details',
    component: () => import('../views/Introduction/details'),
    meta: {
      title: '攻略详情',
      position: '7'
    }
  },
  {
    path: '/qls_gw/introduction',
    name: 'introduction',
    component: () => import('../views/Introduction/index'),
    meta: {
      title: '游记攻略',
      position: '7'
    }
  },
  {
    path: '/qls_gw/travelGuide',
    name: 'travelGuide',
    redirect: '/qls_gw/travelGuide/PickingExperience',
    component: () => import('../views/travelGuide/index'),
    meta: {
      title: '旅游指南',
      position: '3'
    },
    children: [
      {
        path: '/qls_gw/PickingExperience',
        name: 'PickingExperience',
        meta: {
          title: '采摘体验',
          position: '3'
        },
        component: () => import('../views/travelGuide/components/PickingExperience')
      },
      {
        path: '/qls_gw/Gourmet',
        name: 'Gourmet',
        meta: {
          title: '美食',
          position: '3'
        },
        component: () => import('../views/travelGuide/components/Gourmet')
      },
      {
        path: '/qls_gw/Stay',
        name: 'Stay',
        meta: {
          title: '住宿',
          position: '3'
        },
        component: () => import('../views/travelGuide/components/Stay')
      },
      {
        path: '/qls_gw/Present',
        name: 'Present',
        meta: {
          title: '伴手礼',
          position: '3'
        },
        component: () => import('../views/travelGuide/components/Present')
      },
      {
        path: '/qls_gw/BeltAndRoad',
        name: 'BeltAndRoad',
        meta: {
          title: '一带一路',
          position: '3'
        },
        component: () => import('../views/travelGuide/components/BeltAndRoad')
      },
    ]
  },
  {
    path: '/qls_gw/holidayIntroductiona',
    name: 'holidayIntroductiona',
    redirect: '/qls_gw/holidayIntroductiona/QlsScenicSpot',
    component: () => import('../views/holidayIntroductiona/index'),
    meta: {
      title: '度假区介绍',
      position: '2',
    },
    children: [
      {
        path: '/qls_gw/QlsScenicSpot',
        name: 'QlsScenicSpot',
        meta: {
          title: '青龙山景区',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/QlsScenicSpot')
      },
      {
        path: '/qls_gw/FubaVillage',
        name: 'FubaVillage',
        meta: {
          title: '福坝村',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/FubaVillage')
      },
      {
        path: '/qls_gw/HqVillage',
        name: 'HqVillage',
        meta: {
          title: '花桥村',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/HqVillage')
      },
      {
        path: '/qls_gw/SyVillage',
        name: 'SyVillage',
        meta: {
          title: '山银村',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/SyVillage')
      },
      {
        path: '/qls_gw/Athr',
        name: 'Athr',
        meta: {
          title: '茶马古道',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/Athr')
      },
      {
        path: '/qls_gw/WjbVillage',
        name: 'WjbVillage',
        meta: {
          title: '吴坝村',
          position: '2',
        },
        component: () => import('../views/holidayIntroductiona/components/WjbVillage')
      },
    ]
  },
  {
    path: '/qls_gw/lxbd',
    name: 'lxbd',
    redirect: '/qls_gw/lxbd/jt',
    component: () => import('../views/lxbd/index'),
    meta: {
      title: '旅行宝典',
      position: '6'
    },
    children: [
      {
        path: '/qls_gw/jt',
        name: 'jt',
        meta: {
          title: '交通',
          position: '6'
        },
        component: () => import('../views/lxbd/jt')
      },
      {
        path: '/qls_gw/ask',
        name: 'ask',
        meta: {
          title: '问答',
          position: '6'
        },
        component: () => import('../views/lxbd/ask')
      },
      {
        path: '/qls_gw/xz',
        name: 'xz',
        meta: {
          title: '入园须知',
          position: '6'
        },
        component: () => import('../views/lxbd/xz')
      },
    ]
  },
  {
    path: '/qls_gw/hdty',
    name: 'hdty',
    redirect: '/qls_gw/hdty/Atlas',
    component: () => import('../views/hdty/index'),
    meta: {
      title: '互动体验',
      position: '4'
    },
    children: [
      {
        path: '/qls_gw/hdty/atlas',
        name: 'Atlas',
        meta: {
          title: '图集',
          position: '4'
        },
        component: () => import('../views/hdty/components/Atlas')
      },
      {
        path: '/qls_gw/map',
        name: 'PanoramicMap',
        meta: {
          title: '全景地图',
          position: '4'
        },
        component: () => import('../views/hdty/components/PanoramicMap')
      },
      {
        path: '/qls_gw/video',
        name: 'VideoCollection',
        meta: {
          title: '视频集',
          position: '4'
        },
        component: () => import('../views/hdty/components/VideoCollection')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

router.beforeEach((to, from, next) => {
  let path = to.path
  console.log('路由守护')
  console.log(to)
  console.log(from)
  if (path == "/qls_gw/login") {
    console.log('登录页面')
    if (to.query.code) {
      sessionStorage.setItem("code", to.query.code);
      let ext = {
        from_vue: 1,
        code: to.query.code,
        appid: store.state.appid,
        merchant_id: store.state.merchant_id
      }
      memberLogin(ext).then((res) => {
        console.log(res)
        if (res.status.code == '200') {
          console.log('登录页面1122')
          let cur_member = {
            member_id: res.member_id,
            name: res.name,
            third_session: res.third_session,
            wx_user_id: res.wx_user_id
          }
          sessionStorage.setItem('user_name', res.name)
          sessionStorage.setItem('user_key', JSON.stringify({
            member_id: res.member_id,
            third_session: res.third_session,
          }))
          store.commit('updateMember', JSON.stringify(cur_member))
          next({
            path: "/qls_gw/data_analysis",
          });
          console.log(store.state.cur_member)
        } else {
          console.log('登录页面3344')
          Message.error(res.status.msg)
        }
      })
    } else {
      console.log('登录页面5588')
      next();
    }
  }else{
    console.log('登录页面9900')
    next();
   }
});

export default router
