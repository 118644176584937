import axios from 'axios'
import { MessageBox, Message, Notification } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        config.headers['token'] = 'c26d1c76-a9ac-4cbc-81c4-902ade78797f'
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = getToken()
            config.headers['Content-Type'] = 'application/json'
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        // 兼容blob下载出错json提示
        if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
            const reader = new FileReader()
            reader.readAsText(error.response.data, 'utf-8')
            reader.onload = function (e) {
                const errorMsg = JSON.parse(reader.result).message
                Message.error({
                    title: errorMsg,
                    duration: 5000
                })
            }
        } else {
            let code = 0
            try {
                code = error.response.data.status
            } catch (e) {
                if (error.toString().indexOf('Error: timeout') !== -1) {
                    Message.error({
                        title: '网络请求超时',
                        duration: 5000
                    })
                    return Promise.reject(error)
                }
            }

            if (code) {
                if (code === 401) {
                    store.dispatch('LogOut').then(() => {
                        // 用户登录界面提示
                        Cookies.set('point', 401)
                        location.reload()
                    })
                } else if (code === 403) {
                    router.push({ path: '/401' })
                } else {
                    const errorMsg = error.response.data.message
                    if (errorMsg !== undefined) {
                        Message.error({
                            title: errorMsg,
                            duration: 0
                        })
                    }
                }
            } else {
                Message.error({
                    title: '接口请求失败',
                    duration: 5000
                })
            }
        }
        return Promise.reject(error)
    }
)

export default service