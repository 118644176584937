<template>
    <div>
        <div class="go" @click="goTop">
            <i class="el-icon-arrow-up"></i>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    methods: {
        goTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            this.timer = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 80
                if (top <= 0) {
                    clearInterval(this.timer)
                }
            }, 10)
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
</script>
<style scoped>
.go {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: rgba(45, 25, 25, 0.1);
    color: rgb(51, 51, 51);
    text-align: center;
    font-size: 20px;
    position: fixed;
    left: 3%;
    top: 500px;
    border-radius: 50%;
    opacity: 0.53;
}
</style>