import request from './request.js'
// 埋点
export function getTrackers(data) {
    return request({
      url: `/gw/trackers`,
      method: 'post',
      data: data
    })
  }

  export function getLeave() {
    return request({
      url: `/gw/trackers/leave`,
      method: 'get',
      // data: data
    })
  }
  export function leave_count() {
    return request({
      url: `/gw/trackers/leave_count`,
      method: 'get',
      // data: data
    })
  }
  export function fingerprint() {
    return request({
      url: `/gw/trackers/fingerprint`,
      method: 'get',
      // data: data
    })
  }
  