<template>
  <el-footer class="tabr" style="height: inherit;">
    <div class="up hidden-xs-only" @click="getTrackersFn">
      <div class="up-col up_d1">
        <h6>特色景点</h6>
        <p><router-link to="/qls_gw/QlsScenicSpot">青龙山景区</router-link></p>
        <p><router-link to="/qls_gw/FubaVillage">福坝村</router-link></p>
        <p><router-link to="/qls_gw/HqVillage">花桥村</router-link></p>
        <p><router-link to="/qls_gw/SyVillage">山银村</router-link></p>
        <p><router-link to="/qls_gw/WjbVillage">吴坝村</router-link></p>
        <p><router-link to="/qls_gw/Athr">茶马古道</router-link></p>
      </div>
      <div class="up-col up_d2">
        <h6>互动体验</h6>
        <p><router-link to="/qls_gw/hdty/Atlas">图集</router-link></p>
        <p><router-link to="/qls_gw/map">度假区全景地图</router-link></p>
        <p><router-link to="/qls_gw/video">视频集</router-link></p>
      </div>
      <div class="up-col up_d3">
        <h6>旅行宝典</h6>
        <p><router-link to="/qls_gw/jt">交通指南</router-link></p>
        <p><router-link to="/qls_gw/ask">常见问题</router-link></p>
        <p><router-link to="/qls_gw/xz">入园须知</router-link></p>
      </div>
      <div class="up-col up_d4">
        <h6>联系我们</h6>
        <dl>
          <dt>电话：</dt>
          <dd>0939-5121366</dd>
        </dl>
        <dl>
          <dt>地址：</dt>
          <dd>甘肃省陇南市康县城关中街9号</dd>
        </dl>
      </div>
      <div class="up-col up_d5">
        <img src="https://ct.co-op.ai/icons/a_qls/gw/gf_gzgzb.jpg" alt="">
        <p>关注我们</p>
      </div>
    </div>
    <div class="down">
      <div class="down-c">
        <div class="down-phone">
          <div>电话：</div>
          <div>0939-5121366</div>
        </div>
        <div class="down-address">
          <div>地址：</div>
          <div>甘肃省陇南市康县城关中街9号</div>
        </div>
        <div class="down-about">
          <img src="https://ct.co-op.ai/icons/a_qls/gw/gf_gzgzb.jpg" alt="">
          <div>关注我们</div>
        </div>
      </div>
      <div class="down-b"><span class="hidden-xs-only">Copyright 2016-2020 qlstourism.com.cn</span> 
        备案：<a class="text-color" href="https://beian.miit.gov.cn" target="_blank">陇ICP备14000254号-3</a> 康县旅游网 版权所有</div>
    </div>
  </el-footer>
</template>
<script>
import { getTrackers } from '@/utils/trackers'
export default {
  data() {
    return {
      keyName: {
        '青龙山景区': 'qing_long_shan',
        '福坝村': 'fu_ba_cun',
        '花桥村': 'hua_qiao_cun',
        '山根村': 'shuan_yin_cun',
        '吴坝村': 'wu_jia_ba_cun',
        '茶马古道': 'cha_ma_gu_dao',
        '采摘体验': 'cai_zhai',
        '美食': 'mei_shi',
        '住宿': 'zhu_su',
        '伴手礼': 'ban_shou_li',
        '一带一路': 'yi_dai_yi_lu',
        '图集': 'tu_ji',
        '全景地图': 'quan_jing',
        '视频集': 'shi_pin_ji',
        '景区活动': 'huo_dong',
        '景区公告': 'gong_gao',
        '新闻动态': 'dong_tai',
        '交通指南': 'jiao_tong',
        '常见问题': 'chang_jian',
        '入园须知': 'ru_yuan',
        '游记攻略': 'introduction',
        '在线预订': 'pur_chase',
        '首页': 'home'
      },
    }
  },
  methods: {
    getTrackersFn(e) {
      console.dir(e.target.localName == 'a')
      if (e.target.localName == 'a') {
        getTrackers({
          'tracker': {
            page: this.keyName[e.target.innerHTML],
            fingerprint: localStorage.getItem('fingerprint')
          }
        }).then(res => {
          console.log(res)
        })
      }


    }
  }
}
</script>
<style scoped lang="scss">
.tabr {
  width: 100%;
  background: rgb(43, 43, 43);
}

.el-footer {
  padding: 0;
}

.up {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  color: rgba(255, 255, 255, 0.8);
  font-family: PingFang HK;
  font-size: 16px;
  padding-top: 50px;
  height: 300px;

  .up-col {
    flex: 1;
  }

  .up_d4 {
    flex: 0 0 300px;
    padding-left: 120px;
    border-left: 1px solid rgba(216, 216, 216, 0.1);
    height: 260px;
  }

  .up_d5 {
    display: flex;
    flex: 0 0 104px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    font-size: 16px;
    font-weight: normal;
    line-height: 18.72px;
    letter-spacing: 0em;
    color: #FFFFFF;

    img {
      width: 104px;
    }
  }

  h6 {
    font-size: 20px;
    font-weight: normal;
    line-height: 18.72px;
    letter-spacing: 0em;
    color: #FFFFFF;
    margin: 16px auto 30px;

  }

  p {
    line-height: 30px;
    margin: 0;

    a {
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #B0B0B0;
      text-decoration: none;
    }
  }

  dt {
    margin-bottom: 28px;
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt {
    font-weight: 400;
    font-size: 12;
    line-height: 30px;
  }

  dd {
    font-weight: 400;
    font-size: 18;
    line-height: 30px;
  }

}

.down {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #B0B0B0;
  background: rgb(43, 43, 43);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  a{
        font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #B0B0B0;
    text-decoration: none;
      }
  .down-c {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .tabr {
    width: inherit;
    padding: 0;
  }

  .down {
    height: auto;
    padding: 10px 0;
    line-height: 20px;
    font-size: 12px;
    .down-b{
      padding-top: 10px;
      a{
        font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #B0B0B0;
      }
    }
    .down-c {
      display: flex;
      justify-content: space-evenly;
      height: 70px;
      border-bottom: 1px solid rgb(73, 73, 73);
      padding-bottom: 10px;

      .down-phone,
      .down-address,
      .down-about {
        // width: 100px;
        height: 100%;
        // background-color: aqua;
        color: white;
        text-align: start;
        margin-left: 5px;
      }

      .down-phone,
      .down-address {
        padding-top: 10px;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }

      .down-about {
        img {
          height: 50px;
          display: block;
        }
      }
    }
  }
}</style>
