<template>
    <div class="fwbox hidden-xs-only">

        <div class="aside-box_bottom">
            <div class="boxs hidden-xs-only" @mouseenter="dh_v" @mouseleave="dh_e">
                <div class="img">
                    <img src="https://ct.co-op.ai/icons/a_qls/gw/id-tel2.png" alt="">
                </div>
                <div class="span hidden-xs-only">
                    联系电话
                </div>
                <div class="only-l" style="display: none;">
                    <div class="top">
                        <img src="https://ct.co-op.ai/icons/a_qls/gw/ic-tel3.png" alt="">
                        <div>咨询请拨打电话</div>
                    </div>
                    <div class="bot">0939-5121366</div>
                </div>
            </div>
            <div class="boxs hidden-xs-only" @mouseenter="kf_v" @mouseleave="kf_e">
                <div class="img">
                    <img src="https://ct.co-op.ai/icons/a_qls/gw/ic-zxgt.png" alt="">
                </div>
                <div class="span">
                    在线客服
                </div>
                <div class="erwm-l" style="display: none;">
                    <img src="https://ct.co-op.ai/icons/a_qls/gw/ic-gzh.png" alt="">
                </div>
            </div>


        </div>
        <div class="boxs aside-box_top hidden-xs-only" @click="goTop">
            <div class="img">
                <img style="height: 24px;width: 24px;" src="https://ct.co-op.ai/icons/a_qls/gw/ic-sjt.png" alt="">
                <div style="color: white;font-size: 12px;">返回顶部</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            timer: null
        }
    },
    methods: {
        goTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            this.timer = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 80
                if (top <= 0) {
                    clearInterval(this.timer)
                }
            }, 10)
        },
        dh_v() {
            // if(document.querySelector('.only-l').style.display=='none'){
            document.querySelector('.only-l').style.display = 'block'
            document.querySelector('.erwm-l').style.display = 'none'
            // }else{
            //     document.querySelector('.only-l').style.display='none'
            // }
        },
        kf_v() {
            // if(document.querySelector('.erwm-l').style.display=='none'){
            document.querySelector('.erwm-l').style.display = 'block'
            document.querySelector('.only-l').style.display = 'none'
            // }else{
            //     document.querySelector('.erwm-l').style.display='none'
            // }
        },
        dh_e() {
            document.querySelector('.only-l').style.display = 'none'
        },
        kf_e() {
            document.querySelector('.erwm-l').style.display = 'none'
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
</script>
<style scoped lang="scss">
.fwbox {
    width: 80px;
    height: 600px;
    position: fixed;
    top: 58%;
    right: 0;
    z-index: 9;
}

.aside-box_top {
    width: 80px;
    height: 68px;
    // border-radius: 5px 0px 0 5px;
    opacity: 0.9;
    background: #019641;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

.only-l {
    position: absolute;
    left: -150px;
    top: 0;
    width: 150px;
    height: 81px;
    background-color: white;
    border-radius: 5px 0px 0 5px;
    box-sizing: border-box;
    padding: 10px 15px;

    .top {
        display: flex;
        align-items: center;

        img {
            width: 22px;
            margin-right: 10px;
        }

        div {
            font-size: 12px;
        }
    }

    .bot {
        margin-top: 15px;
        font-size: 17px;
        color: #00923F;
        font-weight: 600;
    }
}

.erwm-l {
    position: absolute;
    left: -160px;
    top: -80px;
    width: 160px;
    height: 160px;
    background-color: white;
    border-radius: 5px 0px 0 5px;
    box-sizing: border-box;
    padding: 10px 15px;
    img{
        width: 100%;
        height: 100%;
        border: 1px dashed  rgb(183, 183, 183);
    }
}

.aside-box_bottom {
    position: relative;
    width: 80px;
    height: 160px;
    // border-radius: 5px 0px 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0.9;
    background: linear-gradient(159deg, #00C473 1%, #019742 95%);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    position: relative;

    &::after {
        content: '';
        width: 80%;
        height: 1px;
        position: absolute;
        left: 12%;
        right: 0;
        top: 81px;
        background-color: #F2F2F2;
    }
}

.aside-box_bottom .boxs {
    flex: 1;
    height: 100px;

}

.boxs {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    .img {
        // width: 24px;
        text-align: center;
        cursor: pointer;

        img {
            width: 28px;
            height: 28px;
        }
    }

    .span {
        margin-top: 6px;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
}</style>