import request from './request.js'
//获取活动列表
export function getEventList(merchant_id,page) {
    return request({
      url: `/gw/events`,
      method: 'get',
      params: {
        merchant_id,
        page
        }
    })
  }
  
  //获取活动详情
  export function getEventAll(id) {
    return request({
      url: `/gw/events/${id}`,
      method: 'get',
    })
  }
  // 登录
  export function memberLogin(ext) {
    return request({
      url: `/gw/merchants/login`,
      method: 'post',
      params: ext
    })
  }