<template>
    <div id="amap">
        <el-amap vid="amap" class="amap" :center="psCenter" :plugin="plugin" :zoom="zoom">
            <!-- <el-amap-marker vid="component-marker" :position="psAdr" /> -->
            <el-amap-marker v-for="(item, index) in markers" :position="item.adr" :key="index" :icon="iconImg"
                :events="showModel" :label="{ content: index + 1 }" />
            <el-amap-info-window v-for="(item, index) in markers" :key="index + 1" :position="item.adr"
                :visible="item.visible" :content="item.content">
            </el-amap-info-window>
        </el-amap>
    </div>
</template>
  
<script>
export default {
    props: {
        psCenter: {
            type: Array,
            default: () => [105.602725, 37.076636]
        },
        markers: {
            type: Array,
            default: () => [{ adr: [105.602725, 37.076636], content: '6666', visible: false }]
        }
    },
    data() {
        return {
            iconImg: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_bs.png',
            zoom: 13,
            plugin: [                          // 这里放扩展的插件

            ],
            showModel: {
                init: (o) => {

                },
                click: (e) => {
                    console.log(e)
                    // let longtitude = e.lnglat.lng
                    // let latitude = e.lnglat.lat
                    // let obj = {
                    //     position: [longtitude, latitude],
                    //     draggable: false
                    // }
                    // this.markers.push(obj)
                }
            },
        };
    },

    mounted() {
    },
    created() {

    },
    methods: {

    }

};

</script>
  
   
  
<style scoped lang="scss">
#amap {
    height: 500px;
    width: 100%;

}

:deep(.amap-logo) {
    display: none;
    opacity: 0 !important;
}

:deep(.amap-copyright) {
    opacity: 0;
}

:deep(.amap-marker-label) {
    background: none;
    border: none;
    width: 18px;
    text-align: center;
    color: #fff;
}
</style>