import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'wowjs/css/libs/animate.css'


import $ from 'jquery'
Vue.prototype.$ = $

// import 'amfe-flexible'
// import 'lib-flexible/flexible.js'

import ScaleBox from '@/components/scaleBox'
import GobackTop from '@/components/gobackTop'
import AmapView from '@/components/amaps'
import Rightfw from "@/components/rightfw";
import VueAMap from "vue-amap";
import echarts from 'echarts'



import scroll from 'vue-seamless-scroll'


import animated from 'animate.css'
Vue.use(scroll)
// Vue.use(animated)


Vue.use(VueAMap)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.component('ScaleBox', ScaleBox)
Vue.component('GobackTop', GobackTop)
Vue.component('AmapView', AmapView)
Vue.component('Rightfw', Rightfw)


Vue.config.productionTip = false

VueAMap.initAMapApiLoader({
  key: "73e9648f63b7747bbaf77e56438d9572",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geocoder",
    "AMap.Geolocation"
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})
// router.beforeEach((to, from, next) => {
//   let user = sessionStorage.getItem("user_name");
//   if (!user) {
//     if (to.path !== '/login') {
//       next({ path: "/login" })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })
router.afterEach((to, form, next) => {
  console.log(to.path)
  if (to.path == '/information' || to.path == '/introduction' || to.path == '/active' || to.path == '/purChase' || to.path == '/data_analysis') {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  } else if (to.path == '/pl_dt') {
    window.scrollTo({
      top: 600,
      left: 0,
    });
  }
});
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
