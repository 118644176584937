import { render, staticRenderFns } from "./rightfw.vue?vue&type=template&id=166a2241&scoped=true&"
import script from "./rightfw.vue?vue&type=script&lang=js&"
export * from "./rightfw.vue?vue&type=script&lang=js&"
import style0 from "./rightfw.vue?vue&type=style&index=0&id=166a2241&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166a2241",
  null
  
)

export default component.exports