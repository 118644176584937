<template>
  <div id="app">
    <Navmenu v-if="!reload && !loading && !showLogin" />
    <div class="main-wrap"><router-view v-if="!loading" /></div>
    <Tabbar v-if="!reload && !loading && !showLogin" />
    <Rightfw></Rightfw>

  </div>
</template>
<script>
import Navmenu from "@/components/nav";
import Tabbar from "@/components/tabBar";
import Rightfw from "@/components/rightfw";
import { getLeave, fingerprint } from '@/utils/trackers'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import Fingerprint2 from 'fingerprintjs2';
export default {
  components: { Navmenu, Tabbar, Rightfw },
  data() {
    return {
      reload: null,
      loading: true,
      showLogin: false,
    }
  },
  beforeCreate() {
    window.addEventListener('load', () => {
      let timer = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.1)'
      });
      this.loading = true
      setTimeout(() => {
        timer.close();
        this.loading = false
      }, 800);
    })
  },
  created() {
    // leave_count().then(res => {
    //   console.log(res)
    // })
    // window.addEventListener("unload", this.closeBrowser)
    window.addEventListener("beforeunload", this.handleBeforeUnload)
    if (!localStorage.getItem('fingerprint')) {
      fingerprint().then(res => {
        console.log(res)
        localStorage.setItem('fingerprint', res.fingerprint)
      })
    }

  },
  mounted() {
    // FingerprintJS.load().then(fp => {
    //   // The FingerprintJS agent is ready.
    //   // Get a visitor identifier when you'd like to.
    //   fp.get().then(result => {
    //     // This is the visitor identifier:
    //     const visitorId = result.visitorId;
    //     sessionStorage.setItem('fingerprint',visitorId)
    //     // console.log(visitorId);
    //   });
    // });
    // this._isMobile()
    // Fingerprint2.get((components) => {
    //   const values = components.map((component) => component.value);
    //   const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
    //   // console.log('Device Fingerprint:', fingerprint);
    //   sessionStorage.setItem('fingerprint',fingerprint)
    //   // 这里可以将设备指纹存储到本地或发送到服务器进行跟踪
    // });
    window.addEventListener('resize', () => {
      if (this.reload) {
        window.location.reload()
      }
    })
  },
  methods: {
    async closeBrowser() {
      await getLeave()
    },
    handleBeforeUnload(event) {
      // 刷新、关闭标签页都会执行
      // 使用 navigator.sendBeacon 发送 POST 请求
      let url = `${process.env.VUE_APP_BASE_API}/gw/trackers/leave`
      let data = new FormData();
      data.append('tracker[fingerprint]', localStorage.getItem('fingerprint'));
      navigator.sendBeacon(url, data);
    }
  },
  watch: {
    '$route': {
      handler(val) {
        if (val.path == '/login' || val.path == '/register' || val.path == '/forgot') {
          this.showLogin = true
        } else {
          this.showLogin = false
        }
        if (val.name !== 'yd_home') {
          sessionStorage.setItem('rtval', val.name)
        }
      },
      deep: true
    }
  },
  beforeDestroy() {

  }
}
</script>
<style lang="scss">
body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  list-style: none !important;
  outline: none !important;
  overflow-x: hidden !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// ::-webkit-scrollbar {
//   width: 1px !important;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 1px;
//   -webkit-box-shadow: inset 0 0 3px #99a9bf;
//   background-color: rgb(238, 238, 238) !important;
// }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0) !important;
  border-radius: 1px;
  background-color: transparent !important;
}

.list-tabs {
  height: 72px;
  background-color: #F2F2F2;

  ul {
    width: 1400px;
    padding: 0;
    margin: 0 auto;
    list-style: none;
    height: 72px;
    display: flex;
    align-items: center;

    li {
      flex: 1;
      text-align: center;
      height: 72px;
      line-height: 72px;

      a {
        cursor: pointer;
        display: block;
        height: 72px;
        line-height: 72px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }
    }

    li.cur {
      background-color: #00923F;

      a {
        color: #fff;
      }
    }
  }
}


.mb {
  width: 62%;
  height: 66px;
  line-height: 66px;
  margin: auto;
  display: flex;
  align-items: center;
  color: rgb(153, 153, 153);
  font-size: 14px;

  .gos {
    color: rgb(0, 146, 63);
  }

  i {
    margin: 0 4px;
  }
}


.detail-header {
  width: 62%;
  margin: 30px auto 0 auto;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(239, 239, 239);

  .detail-title {
    width: 40%;
    color: rgb(51, 51, 51);
    font-size: 24px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  .data_look {
    width: 100%;
    height: 40px;
    display: flex;
    line-height: 40px;
    color: rgb(153, 153, 153);
    font-size: 12px;

    span {
      margin-right: 20px;
    }
  }
}

.detail-content {
  width: 62%;
  margin: 0 auto 40px auto;

  .text {
    line-height: 30px;
    font-size: 12px;
    color: rgb(102, 102, 102);
    margin: 20px auto;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 20px auto;
  }

}

.f_y {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  .sy {
    color: #298f63;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
  }

  .el-pager li.active {
    background: #298f63 !important;
    border: 1px solid #298f63 !important;
    color: white !important;
  }

  .el-pagination .btn-next {
    margin-left: 10px
  }

  :deep(.el-pager) {
    .active {
      background: #298f63 !important;
      border: 1px solid #298f63 !important;
      color: white !important;
      border-radius: 2px;
    }

    .number {
      color: rgb(153, 153, 153);
      border: 1px solid rgb(153, 153, 153);
      margin-right: 6px;
      border-radius: 2px;
    }
  }

  :deep(.btn-next) {
    background: none !important;
    border: 1px solid rgb(153, 153, 153);
    margin-left: 6px;
    color: rgb(153, 153, 153);
    border-radius: 2px;
  }

  :deep(.btn-prev) {
    background: none !important;
    border: 1px solid rgb(153, 153, 153);
    margin-right: 6px;
    color: rgb(153, 153, 153);
    border-radius: 2px;
  }

  :deep(.btn-next):hover {
    background: #298f63 !important;
    border: 1px solid #298f63 !important;
    color: white !important;
    border-radius: 2px;
  }

  :deep(.btn-prev):hover {
    background: #298f63 !important;
    border: 1px solid #298f63 !important;
    color: white !important;
    border-radius: 2px;
  }
}

// 文章列表
.knowMore {
  text-align: center;
  font-size: 18px;
  line-height: 36px;
  margin-top: 45px;

  img {
    width: 20px !important;
    margin-left: 10px;
    vertical-align: middle;

  }
}

.article-item {
  width: 100%;
  height: auto;
  margin: 10px 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }


  .article-item_title_text {
    color: #666666;
    font-family: Noto Sans SC;
    font-size: 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .article-item_date_month {
    color: #B0B0B0;
    ;
    font-family: Inter;
    font-size: 16px;
    text-align: left;
  }
}



.article-item:hover {
  .article-item_date_month {
    color: rgb(0, 146, 63);
  }

  .article-item_title_text {
    color: #333333;
  }

}

// 首页通用块样式
.def-row {
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  img {
    width: 100%;
  }

  .def-warp-po {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    font-family: Source Han Sans CN;
    font-size: 48px;
    font-weight: bold;
    margin: 84px auto 64px;
    color: #333333;
    text-align: center;
  }

  p {
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0em;
    color: #666666;
    margin: 0;
  }

  .def-row_more {
    display: inline-block;
    font-family: Source Han Sans CN;
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0em;
    color: #000000;
    margin-top: 80px;
    margin-bottom: 200px;

    img {
      width: 20px;
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

.el-breadcrumb {
  width: 62%;
  margin: 0 auto;
  padding: 20px 0;

  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #00923F;
  }
}

@media screen and (max-width: 767px) {
  .el-breadcrumb {
    width: auto;
    padding: 20px 20px;
  }

  body .el-popper[x-placement^=bottom] .popper__arrow,
  body .el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #000;
  }

  #app {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow-x: hidden;
  }

  .main-wrap {
    min-height: calc(100% - 40px);
  }

  .mb {
    height: 30px;
    width: 90% !important;
    margin: 0 auto;
    padding: 0px 0;

    span {
      font-size: 14px;
    }

    .el-icon-arrow-right {
      font-size: 14px;
    }
  }

  // 文章列表
  .article-item {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 53px;
    background: rgb(245, 245, 245);
    cursor: pointer;

    .article-item_imgs {
      flex: 0 0 100px;
      height: 100%;
      background-size: cover;
      overflow: hidden;
    }

    .article-item_title {
      flex: 1 1 auto;
      padding: 0 10px;
      height: auto;

      .article-item_title_t {
        color: rgb(51, 51, 51);
        font-family: Noto Sans SC;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        width: 100%;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      .aver {
        font-size: 10px;
        color: rgb(153, 153, 153);

        span {
          margin-right: 5px;
        }
      }

      .article-item_title_text {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        font-size: 12px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 多行省略 */
      }
    }

    .article-item_date {
      flex: 0 0 80px;
      height: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed rgb(204, 204, 204);

      .article-item_date_day {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }

      .article-item_date_month {
        font-size: 11px;
        margin: 10px 0 0;
      }
    }
  }

  .article-item_lg {
    height: 120px;

    .article-item_title {
      flex: 1 1 auto;
      padding: 0 10px;
      height: 100%;

      .article-item_title_text {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  // 分页
  .f_y {
    height: 30px;
    align-content: center;
    margin-top: 20px;

    .sy {
      vertical-align: middle;
      display: inline-block;
      line-height: 30px;
    }

    .el-pagination {
      padding: 0 10px;
      height: 30px;

      button {
        height: 30px;
      }

      .el-pager {
        padding-left: 10px;
      }

      .el-pager,
      .el-pager li {
        vertical-align: top;
        line-height: 30px;
        height: 30px;
      }
    }

  }

  .detail-wrap {
    width: 90% !important;
    margin: 0 auto;

    .detail-header {
      width: 100% !important;
      margin-top: 15px;
      padding-bottom: 10px;

      .detail-title {
        width: 100% !important;
        height: inherit !important;
        font-size: 18px !important;
        margin-bottom: 10px !important;
      }

      .data_look {
        line-height: 20px;
        height: 20px;
      }
    }

    .detail-content {
      padding: 10px 0;
      width: 100% !important;

      .text {
        line-height: 20px !important;
        margin: 10px 0;
      }

      img {
        width: 100% !important;
        margin: 10px 0 !important;
      }
    }
  }
}
</style>
