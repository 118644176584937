<template>
  <el-header class="header" :style="'background:' + background">
    <div class="header-wrap">
      <div class="logo">
        <img :src="logoUrl" />
      </div>
      <div class="nav-right hidden-xs-only">
        <div class="nav-right_top">
          <span class="tel">
            <img src="../assets/ic-tel.png" alt="">
          </span>
          <span>{{ tel }}</span>
          <span class="bor">
            <!-- 康县旅游 -->
          </span>

          <div v-if="user_name" class="logined hidden-xs-only">
            <el-dropdown>
              <p>{{ user_name }} 欢迎您 ！</p>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="login hidden-xs-only" @click="goLogin" v-else>
            登录
          </div>
        </div>
        <div class="nav-right_bottom">
          <el-menu router :default-active='$route.path' menu-trigger="hover" class="el-menu-demo hidden-xs-only"
            mode="horizontal">
            <template v-for="(item, index) in menuList">
              <el-menu-item v-if="!item.subs" :command="item.name" :index="item.name" @click.native="getTrackersFn">{{
                item.navItem }}</el-menu-item>
              <el-submenu :index="item.name" v-if="item.subs">
                <template slot="title">{{ item.navItem }}</template>
                <el-menu-item :command="sub.name" :index="sub.name" v-for="(sub, sub_index) in item.subs"
                  @click.native="getTrackersFn" :key="sub_index">{{ sub.navItem }}</el-menu-item>
              </el-submenu>
            </template>
          </el-menu>


          <!-- <div class="search">
          <input type="text" placeholder="关键词搜索" />
          <i class="el-icon-search"></i>
        </div> -->
          <!-- <img src="../assets/ic-search.png" style="width: 24px;" alt=""> -->
          <img @click="bindDatav" src="../assets/ic-datav.png" style="width: 24px;cursor: pointer;" alt="">

        </div>
      </div>

      <el-dropdown trigger="click" class="xs-nav hidden-sm-and-up" @command="handleCommand">
        <span class="el-dropdown-link">
          <img src="../assets/menu.png" alt="">
        </span>
        <el-dropdown-menu class="header-dorp" slot="dropdown">
          <el-dropdown-item v-for="(item, index) in menuList" :command="item.name" :key="index"
            :index="item.name">{{ item.navItem }}</el-dropdown-item>
          <!-- <el-dropdown-item command="/login">登录 | 注册</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { getTrackers } from '@/utils/trackers'
export default {
  name: "BBSHeader",
  data() {
    return {
      logoUrl: 'https://ct.co-op.ai/icons/a_qls/gw/logo4.png',
      tel: '0939-5121366',
      keyName: {
        '青龙山景区': 'qing_long_shan',
        '福坝村': 'fu_ba_cun',
        '花桥村': 'hua_qiao_cun',
        '山根村': 'shuan_yin_cun',
        '吴坝村': 'wu_jia_ba_cun',
        '茶马古道': 'cha_ma_gu_dao',
        '采摘体验': 'cai_zhai',
        '美食': 'mei_shi',
        '住宿': 'zhu_su',
        '伴手礼': 'ban_shou_li',
        '一带一路': 'yi_dai_yi_lu',
        '图集': 'tu_ji',
        '全景地图': 'quan_jing',
        '视频集': 'shi_pin_ji',
        '景区活动': 'huo_dong',
        '景区公告': 'gong_gao',
        '新闻动态': 'dong_tai',
        '交通指南': 'jiao_tong',
        '常见问题': 'chang_jian',
        '入园须知': 'ru_yuan',
        '游记攻略': 'introduction',
        '在线预订': 'pur_chase',
        '首页': 'home',
        '/qls_gw/purChase': 'pur_chase',
        '/qls_gw/introduction': 'introduction',
        '/qls_gw/jt': 'jiao_tong',
        '/qls_gw/events': 'huo_dong',
        '/qls_gw/hdty/Atlas': 'tu_ji',
        '/qls_gw/PickingExperience': 'cai_zhai',
        '/qls_gw/QlsScenicSpot': 'qing_long_shan',
        '/qls_gw/home': 'home'

      },
      menuList: [
        { name: '/qls_gw/home', navItem: '首页', position: '1' },
        {
          name: '/qls_gw/QlsScenicSpot',
          navItem: '度假区介绍',
          position: '2',
          subs: [
            { name: '/qls_gw/QlsScenicSpot', navItem: '青龙山景区', position: '2', sub_position: '2-1' },
            { name: '/qls_gw/FubaVillage', navItem: '福坝村', position: '2', sub_position: '2-2' },
            { name: '/qls_gw/HqVillage', navItem: '花桥村', position: '2', sub_position: '2-3' },
            { name: '/qls_gw/SyVillage', navItem: '山根村', position: '2', sub_position: '2-4' },
            { name: '/qls_gw/WjbVillage', navItem: '吴坝村', position: '2', sub_position: '2-5' },
            { name: '/qls_gw/Athr', navItem: '茶马古道', position: '2', sub_position: '2-6' }
          ]
        },
        {
          name: '/qls_gw/PickingExperience', navItem: '旅游指南', position: '3',
          subs: [
            { name: '/qls_gw/PickingExperience', navItem: '采摘体验', position: '3', sub_position: '3-1' },
            { name: '/qls_gw/Gourmet', navItem: '美食', position: '3', sub_position: '3-2' },
            { name: '/qls_gw/Stay', navItem: '住宿', position: '3', sub_position: '3-3' },
            { name: '/qls_gw/Present', navItem: '伴手礼', position: '3', sub_position: '3-4' },
            { name: '/qls_gw/BeltAndRoad', navItem: '一带一路', position: '3', sub_position: '3-5' }
          ]
        },
        {
          name: '/qls_gw/hdty/Atlas', navItem: '互动体验', position: '4',
          subs: [
            { name: '/qls_gw/hdty/Atlas', navItem: '图集', position: '4', sub_position: '4-1' },
            { name: '/qls_gw/map', navItem: '全景地图', position: '4', sub_position: '4-2' },
            { name: '/qls_gw/video', navItem: '视频集', position: '4', sub_position: '4-3' }
          ]
        },
        {
          name: '/qls_gw/events', navItem: '活动公告', position: '5',
          subs: [
            { name: '/qls_gw/events', navItem: '景区活动', position: '5', sub_position: '5-1' },
            { name: '/qls_gw/notices', navItem: '景区公告', position: '5', sub_position: '5-2' },
            { name: '/qls_gw/articles', navItem: '新闻动态', position: '5', sub_position: '5-3' }
          ]
        },
        {
          name: '/qls_gw/jt', navItem: '旅行宝典', position: '6',
          subs: [
            { name: '/qls_gw/jt', navItem: '交通指南', position: '6', sub_position: '6-1' },
            { name: '/qls_gw/ask', navItem: '常见问题', position: '6', sub_position: '6-2' },
            { name: '/qls_gw/xz', navItem: '入园须知', position: '6', sub_position: '6-3' }
          ]
        },
        { name: '/qls_gw/introduction', navItem: '游记攻略', position: '7' },
        { name: '/qls_gw/purChase', navItem: '在线预订', position: '8' }
      ],
      activeIndex: '1',
      user_name: '',
      top: 0
    }
  },
  mounted() {
    if (sessionStorage.getItem('user_name')) {
      this.user_name = sessionStorage.getItem('user_name')
    }
    window.addEventListener('scroll', this.getScrollInfo, true)
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if(val.path=="/qls_gw/data_analysis"){
          
          this.user_name = sessionStorage.getItem('user_name')
          // console.log(this.user_name)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getTrackersFn(e) {
      getTrackers({
        'tracker': {
          page: this.keyName[e.target.innerHTML],
          fingerprint: localStorage.getItem('fingerprint')
        }
      }).then(res => {
        console.log(res)
      })
    },
    getScrollInfo() {
      this.top = window.pageYOffset
      // console.log('滚动高度', window.pageYOffset)
    },
    goLogin() {
      this.$router.push('/qls_gw/login')
    },
    bindDatav() {
      this.$router.push('/qls_gw/data_analysis')
    },
    outLogin() {
      sessionStorage.removeItem('user_name')
      this.user_name = ''
    },
    handleCommand(command) {
      console.log(command)
      if (this.keyName[command]) {
        getTrackers({
          'tracker': {
            page: this.keyName[command],
            fingerprint: localStorage.getItem('fingerprint')
          }
        }).then(res => {
          console.log(res)
        })
        this.$router.push(command)
      }

    }
  },
  computed: {
    background: function () {
      return 'rgba(0, 0, 0,' + (this.top * 0.001 + 0.1) + ')'
    }
  },
}
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 120px !important;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
}

.header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
}

.nav-right {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-end;

  .nav-right_top {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    height: 50px;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 5px;

    span.bor {
      padding: 0 5px;
      position: relative;
      display: block;
    }

    img {
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .nav-right_bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      margin-left: 20px;
    }
  }
}

.el-menu.el-menu--horizontal {
  background-color: transparent !important;

  .el-menu-demo {
    display: flex;
    height: 22px;
    justify-content: flex-end;
    align-items: center;
  }
}

.el-menu--horizontal .el-menu--popup-bottom-start {
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(255, 255, 255, 0.2);
  border-radius: 0 0 2px 2px;
  min-width: 100px;

  .el-menu-item {
    background: transparent !important;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0em;
    color: #FFFFFF !important;
    text-align: center;

    &:hover {
      color: #00923F !important;
    }
  }
}

.el-menu--horizontal>.el-menu-item:hover,
.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
  color: white !important;

  &:hover {
    color: white !important;
  }
}

.el-menu--horizontal>.el-menu-item {
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  height: 65px;
  line-height: 60px;
  color: #fff !important;
  border: none
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
  border: none;
  height: 65px;
  line-height: 60px;
  color: #fff !important;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  text-align: center;

  i {
    color: #fff;
  }

  &:hover {
    background-color: transparent !important;
    color: #fff;
  }
}

.el-menu--horizontal>.el-menu-item:hover,
.el-menu--horizontal>.el-menu-item.is-active {
  border: none !important;
  position: relative;
  color: white !important;
  background-color: transparent !important;

  &::after {
    content: '';
    width: 26px;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    margin-left: -13px;
    top: 62px;
    display: block;
    background-color: white;
  }
}

.nav-right_bottom .el-menu--horizontal>.el-menu-item:hover,
.nav-right_bottom .el-menu--horizontal>.el-menu-item.is-active {
  border: none !important;
  position: relative;
  color: #fff !important;
  background-color: transparent !important;

  &::after {
    content: '';
    width: 26px;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    margin-left: -13px;
    top: 62px;
    display: block;
    background-color: #fff;
  }
}

.el-menu--horizontal>.el-submenu {
  margin: 0 20px;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border: none !important;
  color: #fff !important;

  i {
    color: #fff;
  }

  &::after {
    content: '';
    width: 26px;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    margin-left: -23px;
    top: 62px;
    display: block;
    background-color: #fff;
  }
}

.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
  color: #fff;
}

.logo {
  flex: 0 0 200px;
  display: flex;
  align-items: center;

  img {
    height: 60px
  }
}


.el-menu.el-menu--horizontal {
  border: none !important;
}

.search {
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  i {
    position: absolute;
    left: 10px;
    color: #ffffff;
    font-size: 16px;
  }

  input {
    width: 180px;
    height: 30px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgb(255, 255, 255) !important;
    outline: none;
    caret-color: #ffffff;
    padding-left: 36px;
    color: #ffffff;
  }

  input::-webkit-input-placeholder {
    /*WebKit browsers*/
    color: #ffffff;
  }
}

.el-dropdown-selfdefine {
  color: #fff;
}

.menu {
  width: 54%;
  height: 120px;
  margin: auto;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    width: 100%;
    height: 100%;
    line-height: 120px;
    list-style: none;
    position: relative;
    cursor: pointer;
  }

  li:hover {
    opacity: 0.8;
  }
}

.login {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.logined {
  width: 10%;
  cursor: pointer;

  ::v-deep .el-dropdown {
    width: 100%;
    color: #ffffff !important;
    font-size: 16px !important;

    p {
      text-align: center;
    }

  }
}

.el-menu,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: transparent;
}

@media screen and (max-width: 767px) {
  .header {
    height: 50px !important;
    position: relative;
    background-color: #1A815A !important;
  }

  .el-popper .popper__arrow,
  .el-popper .popper__arrow::after {
    background-color: #000;
    border-color: #000;
  }

  .header .search {
    width: 150px;
    position: absolute;
    left: 50%;
    margin-left: -75px;

    input {
      width: 140px;
      height: 20px;
      font-size: 12px;
      padding-left: 30px;
    }

  }

  .header .logo {
    width: auto;
    margin: auto 20px;
    justify-content: flex-start;
    height: 50px;

    img {
      height: 34px !important;
    }


  }

  .el-dropdown-menu__item {
    line-height: 30px;
  }

  .xs-nav {
    position: absolute;
    right: 0px;
  }

  .header-dorp.el-dropdown-menu {
    background-color: #000;
    border-color: #000;

    .el-dropdown-menu__item,
    .el-dropdown-menu__item:hover {
      background-color: #000;
      color: #fff;
    }
  }

  .main-content {
    width: 90%;
  }
}</style>
